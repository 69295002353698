// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    centerData: { lat: 16.056734, lng: 103.652653 },
    provinceData: null,
    districtData: null,
    subDistrictData: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_PROVINCE(state, payload) {
      state.provinceData = payload
    },
    GET_DISTRICT(state, payload) {
      state.districtData = payload
    },
    GET_SUBDISTRICT(state, payload) {
      state.subDistrictData = payload
    },
    UPDATE_CENTER_LOCATION(state, payload) {
      state.centerData.lng = payload.lng
      state.centerData.lat = payload.lat
    },
    UPDATE_CENTER_LOCATION_LAT(state, payload) {
      state.centerData.lat = payload
    },
    UPDATE_CENTER_LOCATION_LNG(state, payload) {
      state.centerData.lng = payload
    },
    UPDATE_NAME_LOCATION(state, payload) {
      state.name = payload
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/serviceArea/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    view({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/serviceArea/view?id=${item.id}`)
          .then(response => {
            // commit('VIEW', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    province({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/hero/serviceArea/province')
          .then(response => {
            commit('GET_PROVINCE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    district({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/serviceArea/district?provinceId=${item.id}`)
          .then(response => {
            commit('GET_DISTRICT', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    subDistrict({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/serviceArea/subdistrict?districtId=${item.id}`)
          .then(response => {
            commit('GET_SUBDISTRICT', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/serviceArea/add', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/serviceArea/update', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateServices({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/serviceArea/updateServices', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/application/hero/serviceArea/delete?id=${item}`)
          .then(response => {
            commit('REMOVE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    mockup({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/hero/serviceArea/mockup')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
