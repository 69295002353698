<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('Services')"
                label-for="register-Services"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Services"
                  vid="Services"
                  rules=""
                >
                  <v-select
                    v-model="services"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="name"
                    :state="errors.length > 0 ? false:null"
                    :options="servicesData"
                    :reduce="(servicesData) => servicesData._id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  BForm, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeModuleServices from '@/store/services/hero/service'

import vSelect from 'vue-select'
import store from '@/store'

const STORE_MODULE_NAME_SERVICE = 'service'

export default {
  components: {
    BSidebar,
    BButton,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      services: [],
      required,
    }
  },
  computed: {
    servicesData() {
      if (store.state[STORE_MODULE_NAME_SERVICE].respData !== null) {
        return this.formatData(store.state[STORE_MODULE_NAME_SERVICE].respData.data)
      }
      return []
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id, services,
          } = this.data
          this.dataId = _id
          this.services = services
        }
      }
    },
  },
  setup() {
    return { STORE_MODULE_NAME_SERVICE }
  },
  created() {
    if (!store.hasModule(STORE_MODULE_NAME_SERVICE)) store.registerModule(STORE_MODULE_NAME_SERVICE, storeModuleServices)
    const obj = {
      currentPage: 0,
      pageSize: 0,
      searchQuery: '',
    }

    this.$emit('update:show', true)

    store
      .dispatch(`${STORE_MODULE_NAME_SERVICE}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.$emit('update:show', false)

        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.$emit('update:show', false)

        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME_SERVICE)) store.unregisterModule(STORE_MODULE_NAME_SERVICE)
  },
  methods: {
    formatData(data) {
      // จัดรูปแบบข้อมูล

      return data.map(e => {
        const indexLang = e.name.findIndex(index => index.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return {
            _id: e._id,
            name: e.name[indexLang].value,
          }
        }
        return {
          _id: e._id,
          name: e.name[0].value,
        }
      })
    },
    initValues() {
      this.dataId = null
      this.services = []
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            services: this.services,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/updateServices`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
